.ant-tabs-nav {
    &::before {
        border-bottom: 0px !important;
    }
}

.fix-ma {
    .ant-tabs-nav-list {
        .ant-tabs-tab {
            margin: 0;
        }
    }

    .ant-tabs-nav-wrap .ant-tabs-nav-list {
        gap: 15px;
    }
}