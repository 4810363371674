.card-licensing {
  width: 200px;
  border-color: $color-input;

  .ant-card-body {
    padding: 0;
  }

  .fs-xs {
    font-size: 18px !important;
    margin-right: 2px;
  }
}

.card-licensing-additional {
  width: 100%;
  border-color: $color-input;
  height: 180px;

  .ant-card-body {
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .fs-xs {
    font-size: 15px !important;
    margin-right: 2px;
  }
}

.div-licenses {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 650px;

  @include respond(phone) {
    flex-direction: column;
  }
}

.div-licenses-main {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.border-light {
  .circle-50 {
    background-color: $color-primary;
    width: 50px;
    height: 50px;
    font-size: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}