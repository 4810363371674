@import './gallerys.scss';
@import './swiper-gallerys.scss';
@import './banner.scss';
@import './collection.scss';
@import './artist-trust.scss';
@import './slider.scss';
@import './img-modal';


.home,
.profile {
  background-color: $color-bg-home;

  .title {
    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    a {
      font-size: 1.3rem;
      color: $color-gray;
      text-decoration: none;
    }
  }

  .ant-tabs-nav {
    &-list {
      gap: 10px;
    }

    &::before {
      border: 0;
    }
  }

  .ant-tabs-tab {
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-gray;

    a {
      color: $color-gray;
    }

    &:hover {
      color: $color-primary;
      ;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-light;
    background-color: $color-primary;
    padding: 0.3rem 2rem;
    border-radius: 8rem;
  }

  .ant-tabs-ink-bar {
    background: transparent;
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      &:nth-child(4) {
        justify-content: end;
        width: 100%;
      }
    }
  }

  .our-artist {
    width: 100%;
    height: 15rem;
    background-image: url(../../../../public/images/19010.jpg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 2rem;
    display: flex;
    position: relative;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100%;

      h1 {
        font-size: 3rem;

        @include respond(tab-port) {
          font-size: 2rem;
        }

        @include respond(phone) {
          font-size: 1.5rem;
        }
      }

      .ant-btn.dark {
        background-color: #000;
        font-weight: bold;
      }
    }
  }
}

@media only screen and (max-width: 768px) {

  .home,
  .profile {
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      padding: 0.2rem 1.5rem;
      font-size: 1rem;
    }

    .ant-tabs-tab {
      font-size: 1rem;
    }
  }
}