.text-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.box-gradient {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 5%;
  height: 100%;
  z-index: 2;
  background: $color-bg-home;
  background: linear-gradient(90deg, rgba($color-bg-home, 1) 0, rgba($color-bg-home, 0) 100%);
}

.box-gradient-end {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 5%;
  height: 100%;
  z-index: 2;
  background: $color-bg-home;
  background: linear-gradient(90deg, rgba($color-bg-home, 0) 0, rgba($color-bg-home, 1) 100%);
}

.linear-gradient {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  z-index: 1;
  background: $color-bg-home;
  background: linear-gradient(0deg, rgba($color-bg-home, 1) 22%, rgba($color-bg-home, 0) 100%);
}

.p-relative {
  position: relative;
}


.box-gradient-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20%;
  z-index: 2;
  background: $color-bg-home;
  background: linear-gradient(0deg, rgba($color-bg-home, 1) 22%, rgba($color-bg-home, 0) 100%);
}

.register {
  padding-top: 20%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.icon-fix {
  vertical-align: baseline !important;
  padding-bottom: 15px;
  margin-top: 4px;
}

.ant-upload-wrapper.fix-width {
  $size: 250px;

  .ant-upload-list.ant-upload-list-picture-card {

    .ant-upload.ant-upload-select,
    .ant-upload-list-item-container {
      width: $size;
      height: $size;
    }
  }
}

.App>.ant-layout {
  min-height: 100dvh;
}