.input-payment {
  .ant-radio-button-wrapper-checked {
    color: $color-light;
    background: rgba($color-primary, 8%) !important;
    border-color: $color-primary !important;
    border-radius: 6px;
    margin-right: 2px;

    &:hover {
      color: $color-primary;
    }
  }

  .ant-radio-button-wrapper {
    color: $color-light;
    background: rgba($color-gray, 5%);
    border-color: rgba($color-gray, 5%);
    border-radius: 6px;
    margin-right: 2px;
    border-width: 1px;
    height: 20px;
    padding-left: 2px;
    padding-right: 2px;

    &:hover {
      border-color: $color-primary;
      color: $color-primary;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
}