.result-order {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 10px;
  & > .ant-result-title{
    color: #FFF !important;
  }

  & > .ant-result-subtitle{
    color: #FFF !important;
  }

  & > .ant-result-extra > .btn-orange-round:hover{
    color: rgba(255, 255, 255, 0.8);;
  }
}