.navbar-fix {
  padding: 0 20px;
  position: fixed;
  top: 0;
  background: $color-dark;
  background: linear-gradient(0deg, rgba($color-black, 1) 0%, rgba($color-black, 1) 0%, rgba($color-dark, 1) 95%);
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 103;

  .right-menu {
    float: right;
    width: 100%;
  }

  .logo {
    float: left;
    padding: 0 20px;

    img {
      height: 40px;
      width: auto;
    }
  }

}

.bars-menu {
  float: right;
  height: 32px;
  padding: 6px;
  display: none;
  background: none;
  margin-left: 5px;

  &:hover {
    background: none !important;
    border: 1px solid $color-primary;
  }
}

.ant-menu-item {
  &:active {
    background-color: $color-primary !important;
  }
}

.ant-drawer-content.nav {
  background: $color-black;

  .ant-drawer-header {
    background: $color-dark;
    background: linear-gradient(0deg, rgba($color-black, 1) 0%, rgba($color-black, 1) 0%, rgba($color-dark, 1) 95%);
    border-bottom: 1px solid $color-gray;
  }
}


.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: $color-black !important;
}

.menuCon {
  max-width: 450px;
  width: 450px;
}

.barsBtn {
  display: block;
  width: 25px;
  height: 3px;
  background: $color-primary;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 25px;
  height: 3px;
  position: absolute;
  top: -6px;
  left: 0;
  background: $color-primary;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 5px !important;
}

.bars-menu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 768px) {
  .bars-menu {
    display: inline-block;
  }

  .right-menu {
    display: none;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }

  .navbar-fix {
    justify-content: space-between;

    .logo {
      width: 50px;
      padding: 0;

      a {
        padding: 0;
      }
    }
  }
}