.banner {
  position: relative;

  .bg-video {
    height: 50vh;
    width: 100%;
    // opacity: .15;

    &__content {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .form-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    color: $color-light;
    text-align: center;
    width: 100%;

    .title {
      font-size: 3rem;

      @include respond(tab-port) {
        font-size: 2rem;
      }

      @include respond(phone) {
        font-size: 1.5rem;
      }

      .primary-title {
        color: $color-primary;
      }
    }
  }
}