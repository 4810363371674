.sliders {
  margin: auto;
  position: relative;
  width: 100%;
  place-items: center;
  overflow: hidden;

  .slider-track-slow {
    display: flex;
    width: calc(250px * 18);
    animation: scroll 80s linear infinite;
    border-radius: 1rem;

    &:hover {
      animation-play-state: paused;
    }
  }

  .slider-track-fast {
    display: flex;
    width: calc(250px * 18);
    animation: scroll 40s linear infinite;
    border-radius: 1rem;

    &:hover {
      animation-play-state: paused;
    }
  }

  .slider {
    height: 220px;
    width: 450px;
    display: flex;
    align-items: center;
    padding: 8px;
    perspective: 100px;

    &:nth-child(3n+0) {
      width: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.2rem;
      object-fit: cover;
      transition: transform 1s;

      &:hover {
        transform: translateZ(5px);
      }
    }
  }

  .item-hover .overlay {
    background: rgba(0,0,0,0.3);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }

  .item-hover:hover .overlay {
    opacity: 1;
    cursor: pointer;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 9));
  }
}