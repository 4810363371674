.footer {
  padding-top: 20px;
  padding-bottom: 20px;
  background: $color-black;
  background: linear-gradient(180deg, rgba($color-black, 1) 0%, rgba($color-black, 1) 0%, rgba($color-dark, 1) 71%);

  .hr {
    border-bottom: 1px solid rgba($color-light, .2);
  }

  .menu-list-footer {
    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0;
      display: flex;
      align-items: center;
      gap: 15px;

      a {
        text-decoration: none;
        color: $color-light;

        &:hover {
          color: rgba($color-light, .5);
        }
      }
    }
  }

  .menu-list-footer-term {
    ul {
      list-style: none;
      padding: 0;
      margin: 10px 0;
      display: flex;
      align-items: center;
      gap: 15px;

      a {
        text-decoration: none;
        color: rgba($color-light, .6);

        &:hover {
          color: rgba($color-light, .2);
        }
      }
    }
  }
}

.copyright,
.footer-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
  }

  .text-copyright {
    color: rgba($color-light, .6);
    font-size: 14px;
    font-weight: 100;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 840px) {

  .copyright,
  .footer-title,
  .social {
    flex-direction: column;
  }

  .footer {
    .container {
      padding: .3rem;
    }
  }

  .footer-title {
    .ant-menu {
      .ant-menu-item {
        padding: 10px;
      }
    }
  }

  .footer {
    .ant-divider {
      margin: 10px 0;
    }
  }

  .copyright,
  .footer-title {
    .text-copyright {
      font-size: 12px;
      margin-top: 10px;
      order: 3;
    }
  }

  .footer {

    .menu-list-footer,
    .menu-list-footer-term {
      font-size: 14px;
    }
  }
}