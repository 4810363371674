@import '../Configuration/variable.scss';

.ant-select-dropdown {
  background-color: $color-bg-home !important;
  border: 1px solid rgba($color-light, 50%) !important;
}

.ant-select-dropdown .ant-select-item {
  color: $color-light !important;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba($color-light, 50%);
  color: $color-bg-home !important;
}

.ant-dropdown .ant-dropdown-menu {
  background-color: $color-bg-home !important;
  border: 1px solid rgba($color-light, 50%) !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  color: $color-light !important;
}