@font-face {
  font-family: 'Noto_Serif_Thai';
  src: local('Noto_Serif_Thai'), url(./../assets/fonts/Noto_Serif_Thai/NotoSerifThai.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto_Serif_Thai';
  font-weight: 900;
  src: local('Noto_Serif_Thai'), url(./../assets/fonts/Noto_Serif_Thai/NotoSerifThai.ttf) format('truetype');
}

@font-face {
  font-family: 'Noto_Serif_Thai';
  font-weight: 900;
  src: local('Noto_Serif_Thai'), url(./../assets/fonts/Noto_Serif_Thai/NotoSerifThai.ttf) format('truetype');
}