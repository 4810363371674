$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

@each $name,
$unit in $spacers {
  .mt-#{$name} {
    margin-top: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .my-#{$name} {
    margin-top: $unit !important;
    margin-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .mx-#{$name} {
    margin-left: $unit !important;
    margin-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .py-#{$name} {
    padding-top: $unit !important;
    padding-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .px-#{$name} {
    padding-left: $unit !important;
    padding-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .p-#{$name} {
    padding-left: $unit !important;
    padding-right: $unit !important;
    padding-top: $unit !important;
    padding-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .pt-#{$name} {
    padding-top: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .pb-#{$name} {
    padding-bottom: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .pe-#{$name} {
    padding-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .ps-#{$name} {
    padding-left: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .me-#{$name} {
    margin-right: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .ms-#{$name} {
    margin-left: $unit !important;
  }
}

@each $name,
$unit in $spacers {
  .mb-#{$name} {
    margin-bottom: $unit !important;
  }
}

$aligns: (
  'center': center,
  'start': left,
  'end': right,
);

@each $name,
$align in $aligns {
  .text-#{$name} {
    text-align: $align;
  }
}

$flex-justify: (
  'center': center,
  'start': flex-start,
  'end': flex-end,
  'between': space-between,
);

@each $name,
$align in $flex-justify {
  .flex-#{$name} {
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: $align;
  }
}