.swiper-gallerys-show {
  height: 15rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.swiper-free-mode {
  height: 5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}