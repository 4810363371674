.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: hidden;

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-logo {
    position: absolute;
    left: 2%;
    top: 2%;
    height: 34px;
    width: auto;
  }

  .copy-right {
    position: absolute;
    bottom: 2%;
    left: 2%;
  }

  .ant-row {
    width: 100%;
    height: 100%;

  }

  .form-input {
    width: 50%;

    @include respond(tab-port) {
      width: 90%;
    }
  }

  label {
    color: $color-gray !important;
  }
}