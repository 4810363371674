.alive-top-navbar {
  background-color: #0A0A09;
  height: 60px;
}

// .alive-top-navbar .logo {
//   width: 100%;
//   margin-top: 12px;
// }

.alive-top-navbar .logo>a>img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ant-menu {
  background: transparent;
  // margin-right: 50px;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item-selected {
  background: #0A0A09;
}

.ant-layout .ant-layout-header {
  background: #0A0A09;
}

// .ant-menu > .ant-menu-item:hover,
// .ant-menu > .ant-menu-submenu:hover,
// .ant-menu > .ant-menu-item-active,
// .ant-menu> .ant-menu-submenu-active,
// .ant-menu > .ant-menu-item-open,
// .ant-menu> .ant-menu-submenu-open,
// .ant-menu > .ant-menu-item-selected,
// .ant-menu > .ant-menu-submenu-selected {
//   color: red;
//   border-bottom: 2px solid red;
// }

.ant-menu>.ant-menu-item .icon-only {
  margin-top: 20px;
}

.btn-orange-round {
  color: $color-light;
  background-color: $color-primary;
  padding: 0.6rem 1.2rem;
  font-weight: bolder;
  border-radius: 8rem;
}

.navbar-search>.input-radius-dark {
  width: 100%;
}

.navbar-search {
  margin-top: 5px;
}

.ant-layout .ant-layout-footer {
  background-color: #0A0A09;
}

.btn-icon-dark {
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.12);
  border: none !important;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.btn-icon-dark:hover {
  color: #0A0A09 !important;
  border: none !important;
  background-color: #FFF;
}

.custom-content {
  display: block;
  overflow: auto;
}

.submenu-item-dark {
  background-color: #0A0A09;
  border: 0 solid #0A0A09;
}

.register-status-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  min-height: 70vh;
}

.content-fixed-height {
  margin-top: 1px;
  min-height: 80vh;
}

.btn-signin {
  color: $color-light;
  background-color: $color-primary;
  font-weight: bolder;
  border-radius: 8rem;
  padding: 0.4rem 1rem;
}

.customize-mask {
  font-size: 20px;
  opacity: 1;
}

.customize-mask .anticon {
  font-size: 32px;
}

.result-error {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 10px;
  height: 80vh;

  &>.ant-result-title {
    color: #FFF !important;
  }

  &>.ant-result-subtitle {
    color: #FFF !important;
  }

  &>.ant-result-extra>.btn-orange-round:hover {
    color: rgba(255, 255, 255, 0.8);
    ;
  }
}

.table-title-center thead[class*="ant-table-thead"] th {
  text-align: center !important;
}

.img-gallery-item>.btn-gallery-delete {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  border-radius: 0;
}

.result-message {
  border-radius: 10px;

  &>.ant-result-title {
    color: #FFF !important;
  }

  &>.ant-result-subtitle {
    color: #FFF !important;
  }

  &>.ant-result-extra>.btn-orange-round:hover {
    color: rgba(255, 255, 255, 0.8);
    ;
  }
}

.radio-topic {
  .ant-radio-button-wrapper-checked {
    color: $color-light;
    background: rgba($color-primary, 8%) !important;
    border-color: $color-primary !important;
    border-radius: 6px;
    margin-right: 10px;

    &:hover {
      color: $color-primary;
    }
  }

  .ant-radio-button-wrapper {
    color: $color-light;
    background: rgba($color-gray, 5%);
    border-color: rgba($color-gray, 5%);
    border-radius: 6px;
    margin-right: 10px;
    border-width: 1px;
    height: unset;
    padding: 1rem 1.3rem;
    width: '100%';

    &:hover {
      border-color: $color-primary;
      color: $color-primary;
    }

    .fs-md {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
      text-align: center;
      line-height: 1;
    }

    .fs-sm {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      line-height: 1;
      margin-top: 5px;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
}

.ch-main-layout {
  padding: 1.5rem;

  @include respond(phone) {
    padding: .4rem;
  }
}

.circle-welcome-artist {
  position: relative;
  height:200px;
  width:200px;
  border-radius: 50%;
  border: 1px solid white;
  font-size: 18px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1rem;
  color: white;
}

.ol-item-spacing > li:not(:last-child) {
  margin-bottom: 5px;
}

.dd-indent {
  margin-left: 20px;
}