.order-summary {
  .ant-card-body {
    padding: 0;
  }

  .order-summary-img {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .btn-cart-close {
    padding-top: 0;
  }

  .ant-card {
    background: transparent;
    border: 0;
    color: $color-light;

    .ant-typography {
      color: $color-light;
    }
  }

  table {
    tr {
      vertical-align: top;
    }
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
  }

  .card-payment {
    padding-left: 1rem;

    @media only screen and (max-width: 1198px) {
      padding-left: 0;
    }
  }
}