$color-primary: #FF5501;
$color-light: #ffffff;
$color-gray: #A2ABBF;
$color-blue-light: #f3f4f5;
$color-white-50: #FBFBFB;
$color-dark: #0D0C0C;
$color-black: #000;

$color-bg-home: #0D0C0C;
$color-input: #31353C;

$color-base: #A2ABBF;
$color-base-secondary: #1D2430;
$color-filters-tag: #d7d5d43d;

$color-primary-50: lighten($color-primary, 20%);