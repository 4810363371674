.tag-plus-style {
  background: transparent;
  border: 2px solid $color-gray !important;
  border-radius: 1rem;
  color: $color-gray;
  padding: 2px 10px;
}

.custom-tag {
  .ant-tag {
    color: $color-gray;
    border: 0;
  }
}

.tag-outline {
  background: rgba($color-light, 12%);
  color: $color-light;
}