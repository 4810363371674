.product {
  .ant-card {
    background: transparent;
    color: $color-light;
    font-weight: bold;

    .img-profile {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      border: 7px solid rgba($color-primary, .4);
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .ant-card-body {
      padding: 24px 0;
    }
  }

  .ant-card-bordered {
    border: 0;
  }

  .ant-card-cover {
    width: 100%;
    height: 622px;
    max-height: 550px;
    max-width: 100%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
      // transition: 0.5s all ease-in-out;

      // &:hover {
      //   transform: scale(1.2);
      // }
    }
  }
}