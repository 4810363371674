.gallery {
  columns: 5 250px;
  gap: 15px;

  @include respond(phone) {
    columns: 2 150px;
    gap: 6px;
  }

  .box-images {
    height: auto;
    overflow: hidden;
    display: block;
    border-radius: 5px;
    margin-bottom: 10px;

    &:hover {
      .gallery-img {
        transform: scale(1.1);
        opacity: .6;
      }
    }

    .gallery-img {
      width: 100%;
      transition: all .2s ease-in-out;
    }
  }

  .gallery-item {
    .ant-skeleton-image {
      height: 350px !important;
      margin: 10px 0;
    }

    &:nth-child(2) .ant-skeleton-image,
    &:nth-child(6) .ant-skeleton-image,
    &:nth-child(12) .ant-skeleton-image,
    &:nth-child(16) .ant-skeleton-image {
      height: calc(350px / 2) !important;
    }
  }
}

.box-gallery {
  overflow: hidden;
  height: 800px;
  position: relative;

  .view-all {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 102;
    background: $color-bg-home;
    background: linear-gradient(0deg, rgba($color-bg-home, 1) 0, rgba($color-bg-home, 0) 95%);
    width: 100%;
    height: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @include respond(phone) {
      opacity: .2;
    }
  }
}

.collection {
  .box-gallery {
    display: none;

    .view-all {
      display: none;
    }
  }
}