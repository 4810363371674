.collection {

  .box-collection {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    width: 100%;
    height: 300px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-sm {
      display: flex;
      overflow: hidden;
      height: 120px;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      margin-top: 2px;

      .img {
        width: calc(100% / 3);
        margin-right: 2px;

        &:last-child {
          margin-right: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-detail {
      text-align: center;
      color: $color-light;

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
      }
    }
  }
}