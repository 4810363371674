.img-modal {
  max-width: 100%;

  .modal-content {
    background-color: transparent;
  }

  .modal-body {
    display: block;
    margin: auto;
    padding: 0;

    img {
      width: auto;
      min-height: 90vh;
      max-height: 90vh;

      @include respond(tab-land) {
        width: 100%;
        min-height: unset;
        max-height: unset;
      }

      @include respond(tab-port) {
        width: 100%;
        min-height: unset;
        max-height: unset;
      }

      @include respond(phone) {
        width: 100%;
        min-height: unset;
        max-height: unset;
      }
    }

    video {
      width: 100%;
      max-height: 90vh;

      @include respond(tab-land) {
        width: 100%;
        max-height: unset;
      }

      @include respond(tab-port) {
        width: 100%;
        max-height: unset;
      }

      @include respond(phone) {
        width: 100%;
        max-height: unset;
      }
    }

    .button-close {
      position: absolute;
      z-index: 999;
      right: 10px;
      top: 10px;
    }
  }


}