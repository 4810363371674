.btn-outline-border {
  background: transparent;
  border-color: $color-gray;
  border-width: 2px;
  color: $color-gray;

  &:hover {
    border-color: $color-primary !important;
    color: $color-primary !important;
    background: transparent !important;
  }

  &.active {
    background: $color-primary !important;
    border-color: $color-primary !important;
    color: $color-light !important;
  }

  svg {
    vertical-align: baseline;
  }

  &:disabled {
    color: rgba($color-light, 12%) !important;
  }
}

.btn-outline {
  background: rgba($color-light, 12%);
  color: $color-light;

  &:hover {
    background: $color-primary !important;
    border: 0
  }

  &.active {
    background: $color-primary !important;
    border: 0
  }
}

.btn-primary {
  background: $color-primary;
  border-color: $color-primary;
  border-width: 2px;
  color: $color-light;

  &:hover {
    background: rgba($color-primary, .8) !important;
    border-color: rgba($color-primary, .8) !important;
    color: rgba($color-light, .8) !important;
  }

  &:disabled {
    background: rgba($color-primary, .8) !important;
    border-color: rgba($color-primary, .8) !important;
    color: rgba($color-light, .8) !important;
  }
}

.btn-light {
  background: $color-light;
  border-color: $color-light;
  border-width: 2px;
  color: rgba($color-base-secondary, .8) !important;

  &:hover {
    background: rgba($color-light, .8) !important;
    border-color: rgba($color-light, .8) !important;
    color: rgba($color-base-secondary, .8) !important;
  }
}

.btn-link {
  text-decoration: none;
  color: $color-primary !important;

  &:hover {
    color: #FF884D !important;
  }
}

.link-nonUnderline {
  text-decoration: none;
}

.btn-link-blue {
  text-decoration: none;
  color: #1677ff !important;

  &:hover {
    color: #4096ff !important;
  }
}

.btn-primary-large {
  background: $color-primary;
  border-color: $color-primary;
  border-width: 2px;
  font-size: 2em;
  font-weight: bold;
  height: fit-content;
  padding: 10px;
  border-radius: 10px;
  color: $color-light;

  &:hover {
    background: rgba($color-primary, .8) !important;
    border-color: rgba($color-primary, .8) !important;
    color: rgba($color-light, .8) !important;
  }

  &:disabled {
    background: rgba($color-primary, .8) !important;
    border-color: rgba($color-primary, .8) !important;
    color: rgba($color-light, .8) !important;
  }
}