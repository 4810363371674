@import '../src/styles/styles';

body,
html {
  margin: 0;
  font-family: 'Inter', 'Noto_Serif_Thai' !important;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FAFAFA;
  background-color: $color-bg-home;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.dark .ant-btn-primary {
  box-shadow: none;
}

body.dark .ant-input-affix-wrapper:focus,
body.dark .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.container {
  max-width: 1800px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;

  @media only screen and (max-width: 767px) {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

.pointer {
  cursor: pointer;

  &:hover {
    color: $color-primary;

    &.ant-tag .ant-tag-close-icon {
      color: $color-primary;
    }
  }
}

.requiredSpecial::before {
  content:"* ";
  color: red;
  size: 2px;
  padding: 0 0 8px;
    margin: 0;
    white-space: initial;
    text-align: start;
}

.containerImage { position: relative; }
.containerImage img { display: block; }
.containerImage .video { display: block; }
.containerImage .favorite { 
  font-size: large;
  z-index: 101; 
  position: absolute;
   top: 5px; 
   right: 8px;
   transform: translate(-2%, -5%);
  color: silver;
  }
.containerImage .fa-download { 
  font-size: large;
  z-index: 101; 
  position: absolute; 
  top:27px; 
  right: 8px; 
  transform: translate(-5%, -2%);
  color: silver;};

.containerImage .artist {
  z-index: 101; 
  position: absolute; 
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
}

.containerImage .favorite-search { 
  font-size: large;
  z-index: 101; 
  position: absolute;
   top: 5px; 
   right: 8px;
   transform: translate(-2%, -5%);
  color: silver;
  }
.containerImage .fa-download-search { 
  font-size: large;
  z-index: 101; 
  position: absolute; 
  top:27px; 
  right: 8px; 
  transform: translate(-5%, -2%);
  color: silver;}

.containerImage .favorite-rec {
  font-size: large;
  z-index: 101; 
  position: absolute; 
  top:10px; 
  right: 22px; 
  color: silver;
}
.containerImage .fa-download-rec { 
  font-size: large;
  z-index: 101; 
  position: absolute; 
  top:28px; 
  right: 22px; 
  color: silver;
}
.containerImage .artist-rec {z-index: 101; text-align: center; position: absolute; bottom: 6%; right: 45%;}
.containerImage .favorite-rec-bt { z-index: 101; position: absolute; top:6%; right: 5%; color: whitesmoke;}
.containerImage .fa-download-rec-bt { z-index: 101; position: absolute; top:20%; right: 5%; color: whitesmoke;}
.containerImage .artist-rec-bt {z-index: 101; text-align: center; position: absolute; top: 25%; left: 20%;}


.sticky-position{
  position: fixed;
  top: 85%;
  right: 5px;
  z-index: 102;
}

.message-margin{
  margin: 30px;
}


.banner-margin-t-70{
  margin-top: 70px;
}

.banner-margin-t-50{
  margin-top: 30px;
}

.banner-margin-t-40{
  margin-top: 30px;
}

.banner-margin-t-30{
  margin-top: 30px;
}

.banner-margin-t-20{
  margin-top: 20px;
}

.banner-margin-t-10{
  margin-top: 10px;
}

.mt-6 {
  margin-top: 6rem;
}