.custom-tab {
  .ant-tabs-nav {
    &::before {
      border: 0;
    }
  }

  .ant-tabs-tab {
    font-size: 1.3rem;
    font-weight: bold;
    color: $color-gray;

    a {
      color: $color-gray;
    }

    &:hover {
      color: $color-primary;
      ;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $color-light;
    background-color: $color-primary;
    padding: 0.3rem 2rem;
    border-radius: 8rem;
  }

  .ant-tabs-ink-bar {
    background: transparent;
  }

  .ant-tabs-nav-list {
    width: 100%;

    .ant-tabs-tab {
      &:nth-child(4) {
        justify-content: end;
        width: 100%;
      }
    }
  }
}

.box-tabs {
  position: relative;

  .box-end {
    position: absolute;
    top: 40px;
    right: 0;
    font-weight: 300;

    @include respond(phone) {
      top: -9px;
    }

    .ant-space-item {
      font-weight: bold;
      color: $color-gray;
    }
  }

  @media only screen and (max-width: 768px) {
    .ant-tabs-tab {
      font-size: 1rem;
    }

    .custom-tab {
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        padding: 0.3rem 1rem;
      }

      .ant-tabs-tab {
        margin: 0 0 0 14px;
      }
    }
  }
}