.my-upload {
    color: #000000;

    .my-upload-card-body {
        min-height: 88dvh;

        @include respond(tab-land) {
            min-height: auto;
        }
    }

    .card-thumnail {
        width: 150px;
        height: 150px;
        border: none;
        margin: 10px 10px 0px 0px;

        img {
            object-fit: cover;
            width: 150px;
            height: 150px;
            border-radius: 8px;
            pointer-events: none;
        }

        .checkbox-thumnail {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 12;
        }

        .filename-label {
            position: absolute;
            bottom: 10px;
            left: 10px;
            font-size: smaller;
        }
    }

    .release-panel {
        height: 120px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 10px;
        border: 1px solid #cecece;
        border-radius: 6px;
        padding: 10px;
    }

    .download-releases {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 10;
    }
}