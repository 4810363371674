.ant-skeleton {
  width: 100% !important;
  height: 100% !important;

  &-image {
    width: 100% !important;
    height: 100% !important;
    background: #b9b9b963 !important;
  }

  &-title {
    background: #b9b9b963 !important;
  }

  &-paragraph>li {
    background: #b9b9b963 !important;
  }
}
