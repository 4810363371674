.card-order {
  .descriptions-card {
    .ant-descriptions-row {
      font-size: 16px;

      .ant-descriptions-item-label {
        padding-left: 0;
      }
    }

    @media only screen and (max-width: 767px) {
      .ant-descriptions-row {
        font-size: 10px;
      }
    }
  }

  .img-gallery-item {
    width: 160px;
    height: 160px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-history {
    .card-title {
      display: flex;
      align-items: center;
      gap: 5px;

      @include respond(phone) {
        flex-direction: column;
        align-items: start;
      }
    }
  }
}

.card-pricing {
  padding: 2rem;

  @include respond(phone) {
    padding: 1rem;
  }

  .ant-card-body {
    .btn-primary {
      padding: 0.2rem 0.5rem;
      white-space: nowrap;
    }
  }
}