.faq {
  a {
    color: $color-gray;
    text-decoration: underline;
    font-weight: 300;

    &:hover {
      color: $color-gray;
    }
  }

  .question {
    list-style: none;
    padding-left: 0;

    li {
      &:nth-child(1) {
        &::before {
          content: "Q : ";
          font-weight: 300;
        }
      }

      &:nth-child(2) {
        &::before {
          content: "A : ";
          font-weight: 300;
        }
      }
    }
  }
}