.table-responsive {
  width: 100%;
  overflow: hidden;
  overflow-x: scroll;

  .table {
    width: 100%;
    color: $color-light;
    white-space: nowrap;
  }
}