.favorite-folder-tab-item {
  display: flex;
  justify-content: space-between;
  width: 200px;

  .folder-name-box {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 4px;
  }

  .folder-name-suffix {
    margin-left: 10px;
  }
}

.favorite-action-label {
  margin-left: 10px;
}

.tabs-favorite {
  .ant-tabs-tab {
    padding: 0 !important;
  }
}