.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-center-items {
  display: flex;
  justify-content: center;
  align-items: center;
}


.fs-xs {
  margin: 0;
  font-size: 10px !important;
  line-height: 1;
  margin-top: 5px;
}

.inline {
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
}

.fw-bold {
  font-weight: bold !important;
}

.hr-gray {
  border: none;
  height: 2px;
  color: $color-input;
  background-color: $color-input;

}

.w-100 {
  width: 100% !important;
}

.text-gradient {
  background: linear-gradient(44deg, #F65807 40%, #7A4328 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ul {
  padding-left: 15px;
}

.circle {
  width: 35px;
  height: 35px;
  background: rgba($color-light, 10%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.ant-divider {

  &::before,
  &::after {
    border-block-start-color: rgba($color-base, 30%) !important;
  }
}

.radius-32 {
  border-radius: 32px;
}

.fw-light {
  font-weight: 200;
}

.fw-normal {
  font-weight: 300;
}

.btn-google {
  iframe {
    margin: 0 !important;
    width: 100% !important;
  }

  #container.haAclf {
    padding: 0 !important;
    width: 100% !important;
  }
}