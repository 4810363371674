.sidebar {
  height: 100vh;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  // &:hover {
  //   width:200px;
  // }
}

.sidebar .img-profile {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 25%;
  margin-bottom: 20px;
}

.sidebar .section-menu {
  list-style-type: none;
  padding-inline-start: 0px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.sidebar .section-menu li {
  margin-top: 20px;
  text-align: left;
}

.sidebar .section-menu li>a {
  color: #9ca3af;
  font-size: 1.2em;
  text-decoration: none;

  &:hover {
    color: #fff;
  }

  &.active {
    color: #fff;
  }
}

.sidebar .section-footer {
  position: absolute;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  bottom: 0;
}

.sidebar .section-footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  display: inline-block;
  margin: 0;
  padding: 0;
}

.sidebar .section-footer ul li {
  margin-bottom: 40px;
}

.sidebar .section-footer ul li>a {
  color: #9ca3af;
  font-size: 2em;

  &:hover {
    color: #fff;
  }
}

.ch-logo {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: center;
  background-color: #0a0a09;

  .ch-img {
    img {
      height: 35px;
    }
  }
}

.ant-layout-sider {
  display: block;

  @include respond(tab-port) {
    display: none;
  }

  .ant-menu {
    height: calc(100% - 64px);
    background-color: #fff;
  }
}

.drawer-menu-mobile {
  .ant-menu {
    border-inline-end: 0 !important;
  }
}