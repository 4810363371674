.artist-upload {
    color: black;

    .bg-grey {
        background-color: #F5F5F5;
        border-radius: 5px;
    }

    .li-bullet-none {
        list-style-type: none;
    }

    .text-left {
        text-align: left;
    }

    .text-right {
        text-align: right;
    }

    .text-center {
        text-align: center;
    }

    .ant-upload-wrapper .ant-upload-drag {
        height: 200px;
    }
}