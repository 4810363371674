.ant-layout .ant-layout-header {
  padding: 0 5px;

  &.ch-nav {
    display: flex;
    justify-content: space-between;
    color: #fff;

    .ch-icon {
      font-size: 20px;
    }
  }

  .ant-menu-item {
    padding-inline: 15px;

    &:last-child {
      @include respond(tab-port) {
        font-size: 16px;
        white-space: nowrap;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .ch-icon {
    display: flex;
    align-items: center;
    gap: 12px;

    .ch-logo-small img {
      height: 40px;
      display: none;

      @include respond(tab-port) {
        display: inline-flex;
      }
    }
  }
}

.badge-notify {
  .ant-badge-count {
    transform: translate(50%, 85%);
  }
}

.btn-open-drawer {
  display: none;

  @include respond(tab-port) {
    display: inline-flex;
  }
}

.btn-collapsed {
  display: inline-flex;

  @include respond(tab-port) {
    display: none;
  }
}