.price {
  .img-info {
    position: relative;
    width: 100%;
    height: 500px;

    @include respond(phone) {
      height: auto;
    }

    .but-img-zoom {
      position: absolute;
      top: 15px;
      right: 30px;
      font-size: 20px;
      transition: 0.5s all ease-in-out;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .info-profile {
    display: flex;
    align-content: center;
    justify-content: space-between;

    @include respond(phone) {
      flex-direction: column;
    }

    .info-name,
    .info-social {
      display: flex;
      align-content: center;
    }

    @include respond(phone) {
      .info-social {
        margin-top: 20px;
      }
    }
  }
}