@import './card.scss';

.product {
  .ant-radio-button-wrapper-checked {
    color: $color-light;
    background: rgba($color-primary, 8%) !important;
    border-color: $color-primary !important;
    border-radius: 6px;
    margin-right: 10px;

    &:hover {
      color: $color-primary;
    }
  }

  .ant-radio-button-wrapper {
    color: $color-light;
    background: rgba($color-gray, 5%);
    border-color: rgba($color-gray, 5%);
    border-radius: 6px;
    margin-right: 10px;
    border-width: 1px;
    height: unset;
    padding: 1rem 1.3rem;
    width: 150px;

    &:hover {
      border-color: $color-primary;
      color: $color-primary;
    }

    .fs-md {
      margin: 0;
      font-size: 17px;
      font-weight: 600;
      text-align: center;
      line-height: 1;
    }

    .fs-sm {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      line-height: 1;
      margin-top: 5px;
    }
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0px;
  }
}

.boxradius {
  border-radius: 10px;
  border: 1px solid rgba($color: #FFF, $alpha: 0.2);
}


.product-img {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.product-vdo {
  position: relative;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.bg-video {
  height: 50vh;
  width: 100%;
  // opacity: .15;

  &__content {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.fav-modal>.modal-content {
  background-color: $color-bg-home;
  border: 1px solid lighten($color-bg-home, 5%);
}

.dark-modal>.modal-content {
  background-color: $color-bg-home;
  border: 1px solid lighten($color-bg-home, 5%);
}

.btn-favorite-folder {
  background: rgba($color-gray, 5%);
  border-color: rgba($color-gray, 5%);
  border-radius: 6px;
  margin-right: 10px;
  border-width: 1px;
  height: 100%;
  padding: 1rem 1.3rem;
  width: 100%;
  text-align: center;
  color: $color-primary;
  cursor: pointer;

  &:hover {
    border-color: $color-primary;
    color: $color-light;
    background: $color-primary;
  }

  &.active {
    border-color: $color-primary;
    color: $color-light;
    background: $color-primary;
  }

  .anticon {
    display: block;
    font-size: 4em;
  }
}

.payout-modal>.modal-content {
  color: $color-dark;
}