.form-dark {
  color: $color-gray;

  label {
    color: $color-gray !important;
  }
}

.box-filters {
  background-color: rgba($color-light, 4%);
  border-radius: 8px;
  padding: 1rem;
  color: $color-light;
  max-height: unset;
  overflow: hidden;

  &.fix-height-box-filters {
    max-height: 84px;
  }

  .ant-typography {
    color: $color-base;
  }

  .ant-tag {
    padding: 0 .5rem;
    border-radius: 8rem;
    background: $color-filters-tag;
    color: $color-light;
    border: 0;
    font-size: 17px;
    border: 1px solid $color-filters-tag;
    display: flex;
    align-items: center;
    height: 26px;

    .ant-tag-close-icon {
      color: $color-light;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  .applied-filters {
    font-size: 18px;

    .ant-tag {
      font-size: 16px;
    }
  }

  .clear-all {
    text-align: right;

    .ant-tag {
      font-size: 18px;
    }
  }
}

.card-form {
  background-color: $color-blue-light;
  border: 2px solid $color-white-50;
  border-radius: 8px;
}

@media only screen and (max-width: 840px) {
  .box-filters {
    .applied-filters {
      font-size: 14px;

      .ant-tag {
        font-size: 12px;
      }
    }

    .clear-all {
      margin-top: 10px;

      .ant-tag {
        font-size: 12px;
      }
    }
  }
}

.box-warning {
  padding: 0.5rem;
  border: 1px solid $color-primary;
  border-radius: 6px;
  background-color: #20120B;

  span {
    display: inline-flex;
  }
}

.tag-item {
  padding: 0 .5rem;
  border-radius: 8rem;
  background: $color-filters-tag;
  color: $color-light;
  border: 0;
  font-size: 17px;
  border: 1px solid $color-filters-tag;
  display: flex;
  align-items: center;
  height: 26px;
}

.upload-form-box {
  border: 1px solid #cecece;
  border-radius: 8px;
  padding: 4px;
  min-height: 42px;
}

span.text-ellipsis-2r {
  line-height: 1em;
  max-height: 2em;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

div.noti-item-box {
  width: 100%;

  div.noti-item-box-head {
    width: 100%;
    display: flex;
    justify-content: space-between;

    span.noti-head-left {
      font-size: small;
      text-align: left;
      font-weight: bold;
      width: auto;
    }
    
    span.noti-head-right {
      font-size: smaller;
      text-align: right;
      min-width: 105px;      
    }
  }
  div.noti-item-box-message {
    padding-top: 5px;
    color: dimgray;
    font-size: smaller;
  }
}

img {
  pointer-events: none;
}