.App {
  min-height: 100vh;
}

.header-content {
  display: inline-flex;
  vertical-align: middle;
}

.header-logo {
  margin-right: 3rem;
}

.custom-content {
  padding: 24px;
  margin: 0px;
  min-height: 300px;
}

.custom-layout-main {
  min-height: 100vh;
}

.custom-layout {
  padding: 24px;
}

.heaer-input-search {
  width: 200px;
}