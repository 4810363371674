$theme-colors: (
  "primary": $color-primary,
  "light": $color-light,
  "gray":$color-gray,
  "primary-50":$color-primary-50,
);

@each $name,
$color in $theme-colors {
  .text-#{$name} {
    color: $color !important;
  }
}

@each $name,
$color in $theme-colors {
  .bg-outline-#{$name} {
    color: $color !important;
    background-color: rgba($color, 5%);
    padding: 1rem;
  }
}

@each $name,
$color in $theme-colors {
  .bg-#{$name} {
    background-color: $color;
  }
}