.custom-color-radio {
  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $color-primary;
    background-color: $color-primary;
  }

  .ant-radio-inner {
    background-color: $color-input;
    border-color: $color-input;
  }

}

.radio-type-customer {
  .ant-tabs-tab {
    background: #141415;
    border: 2px solid #141415;
    border-radius: 8px;
    padding-left: 1rem;

    &.ant-tabs-tab-active {
      background: rgba($color-primary, 5%);
      border: 2px solid $color-primary;
      border-radius: 8px;
      padding-left: 1rem;
    }

    .ant-radio-wrapper {
      color: $color-light;
    }
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: unset;
  }
}

@media only screen and (max-width: 767px) {
  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0;
  }
}