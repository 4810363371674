.artist-trust {
  position: relative;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    height: 25px;

    @include respond(tab-port) {
      font-size: 1rem;
      // height: unset;
    }

    @include respond(phone) {
      font-size: .8rem;
    }
  }
}

.card-art {
  width: calc(100% / 4);

  @include respond(tab-land) {
    width: calc(100% / 3);
  }

  @include respond(tab-port) {
    width: calc(100% / 2);
  }

  @include respond(phone) {
    width: 100%;
  }

  .img-big {
    .ant-avatar {
      width: 50px;
      height: 50px;
    }
  }

  .ant-card {
    // background: rgba(#1D2430, .5);
    background: transparent;
    border: 0;
    border-radius: 1rem;

    .ant-card-meta-title {
      color: $color-light;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .ant-card-meta-description {
      color: $color-light;
    }
  }

  &.skeleton {
    .ant-card-cover {
      border-radius: 1rem;
      overflow: hidden;
      min-height: 250px;
      display: grid;
    }
  }
}