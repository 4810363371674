.input-radius-dark {
  border-radius: 3rem;
  border-color: $color-input;
  background-color: $color-input;
  color: $color-light;

  &.non-radius {
    border-radius: 6px;

    .ant-select-selector {
      border-radius: 6px !important;
      border-color: $color-input !important;
      background-color: $color-input !important;
      color: $color-gray !important;

      &:hover,
      &:focus {
        border-color: $color-input !important;
      }
    }

    .ant-select {

      &:hover,
      &:focus,
      &:active {
        border-color: $color-input !important;
      }
    }

    .ant-select-focused,
    .ant-select:not {
      border-color: $color-gray !important;
    }
  }

  &:hover,
  &:focus {
    border-color: $color-input !important;
  }

  .icon {
    color: $color-light;
    font-size: 1rem;
  }

  input {
    background-color: $color-input;
    color: $color-gray;
  }

  .ant-select-selector {
    border-radius: 3rem !important;
    border-color: $color-gray !important;
    background-color: $color-bg-home !important;
    color: $color-gray !important;

    &:hover,
    &:focus {
      border-color: $color-gray !important;
    }
  }

  .ant-select {

    &:hover,
    &:focus,
    &:active {
      border-color: $color-gray !important;
    }
  }

  .ant-select-arrow {
    color: $color-gray;
  }

  .ant-select-focused,
  .ant-select:not {
    border-color: $color-gray !important;
  }
}

.input-radius-dark-full {
  width: 100%;
  border-radius: 3rem;
  border: 1px solid #31353C;
  background-color: $color-input;

  .icon {
    color: $color-light;
    font-size: 1rem;
  }

  input {
    background-color: $color-input;
    color: $color-light;
  }

  @include respond(tab-port) {
    width: 50%;
  }

  @include respond(phone) {
    width: 80%;
  }

  .input-radius-dark-large {
    height: 100px !important;
  }
}

.small-input {

  &.hide {
    display: none;
  }

  width: calc(25% - 8px);
}

.inputNumber-text-light > .ant-input-number-input-wrap > .ant-input-number-input {
  color: #fff;
}